<template>
  <div>
    <h3>Mijn services</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend
      metus enim, eu tristique dui sollicitudin sit amet. Sed interdum, felis
      quis tincidunt condimentum, nisl elit tempor justo, dictum pulvinar velit
      metus eu eros.
    </p>
    <hr />

    <b-row>
      <b-col md="4" sm="6" xs="12">
        <b-form-radio-group
          id="displayOptions"
          v-model="displayPeriodUom"
          label="Kalender weergave"
          label-for="displayOptions"
          class="mb-3"
        >
          <b-form-radio value="week">Week</b-form-radio>
          <b-form-radio value="month">Maand</b-form-radio>
          <b-form-radio value="year">Jaar</b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>

    <calendar-view
      :show-date="showDate"
      :display-period-uom="displayPeriodUom"
      :items="services"
      @click-item="calendarItemClick"
      locale="nl"
      class="theme-default holiday-us-traditional holiday-us-official mb-5"
    >
      <template #header="{ headerProps }">
        <calendar-view-header
          slot="header"
          :header-props="headerProps"
          @input="setShowDate"
        />
      </template>
    </calendar-view>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'
import { BCol, BRow, BFormRadioGroup, BFormRadio } from 'bootstrap-vue'
// The next two lines are optional themes

import '@/assets/scss/simple-calendar.scss'
import 'vue-simple-calendar/static/css/holidays-us.css'
import { testdata } from '../Serial/servicedata'

import { getServices } from '@/services/CustomerServicesService'

export default {
  name: 'Services',
  components: {
    CalendarView,
    CalendarViewHeader,

    BCol,
    BRow,
    BFormRadio,
    BFormRadioGroup
  },
  data: function() {
    return {
      showDate: new Date(),
      displayPeriodUom: 'month',
      items: testdata,
      services: [],
      serialID: '',
      displayOptions: [
        { value: 'week', text: 'Week' },
        { value: 'month', text: 'Maand' },
        { value: 'year', text: 'Jaar' }
      ]
    }
  },
  created() {
    this.getServices()
  },
  methods: {
    thisMonth(d, h, m) {
      const t = new Date()
      return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
    },
    setShowDate(d) {
      this.showDate = d
    },
    calendarItemClick(event) {
      this.serialID = event.originalItem.serialID
      window.open(`/s/${this.serialID}`)
    },
    async getServices() {
      const services = await getServices({
        customerID: this.$store.state.customerID
      })

      const formattedServices = []

      for (const service of services) {
        formattedServices.push({
          id: service.ServiceID,
          title: `${service.InternalSerialID?.Key ??
            service.ExternalSerialID?.Key} - ${service.Description}`,
          startDate: service.DateTimeServiceStarted ?? service.DateTimeScheduledService.Start,
          endDate: service.DateTimeScheduledServiceEnd ?? service.DateTimeScheduledService.End,
          serialID:
            service.InternalSerialID?.Key ?? service.ExternalSerialID?.Key
        })
      }
      this.services = formattedServices
    }
  }
}
</script>

<style>
.cv-week {
  min-height: 90px;
}
</style>
